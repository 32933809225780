<template>
  <div>
    <window-header></window-header>
    <nav-form>
      <template v-slot:after>
        <button v-if="mode == 'new' || mode == 'edit'" class="button button-fill" @click="importLines">
          <fa-icon icon="file-import" />
          <span>Importar líneas</span>
        </button>
      </template>
      <template v-slot:actions>
        <li v-if="itemId" @click="exportarLineasCSV('pedido_id')">
          Exportar líneas
        </li>
        <li v-if="itemId && mode == 'view'" @click="imprimirPedido">
          Imprimir pedido
        </li>
        <li v-if="itemId && mode == 'view' && formData.estado == 'preparado'" @click="facturar">
          Facturar
        </li>
        <li v-if="itemId && mode == 'view' && formData.estado == 'preparado'" @click="albaranar">
          Albaranar
        </li>
      </template>
      <template v-slot:reports>
        <li
          @click="openURL(app.urlGeneradorInformesDocumentoPrint, { id: app.config.plantilla_documento_pedido_cliente.codigo, conditions: [['id', '=', itemId]] })"
          v-if="itemId && app.config.plantilla_documento_pedido_cliente">
          Documento: {{ app.config.plantilla_documento_pedido_cliente.nombre }}
        </li>
        <li
          @click="openURL(app.urlGeneradorInformesEtiquetaPrint, { id: app.config.plantilla_etiqueta_pedido_cliente.codigo, conditions: [['id', '=', itemId]] })"
          v-if="itemId && app.config.plantilla_etiqueta_pedido_cliente">
          Etiqueta: {{ app.config.plantilla_etiqueta_pedido_cliente.nombre }}
        </li>
        <li
          @click="openURL(app.urlGeneradorInformesEtiquetaPrint, { id: app.config.plantilla_etiqueta_articulo.codigo, conditions: [['codigo', 'IN', formData.lineas.map(x => x.articulo_id)]] })"
          v-if="itemId && app.config.plantilla_etiqueta_articulo">
          Etiquetas de artículo: {{ app.config.plantilla_etiqueta_articulo.nombre }}
        </li>
      </template>
    </nav-form>
    <section>
      <article style="height: 415px">
        <field name="ndoc" widget="char" searchable label="Nº Pedido" width="110px" style="top: 5px; left: 5px" />
        <field name="fecha" type="date" label="Fecha" width="100px" style="top: 5px; left: 130px" />
        <field autofocus ref="cliente_id" name="cliente_id" widget="m2o" label="Cliente" searchable width="320px"
          style="top: 5px; right: 70px" labelProp="nombre_comercial" imgProp="imagen.imagen"
          :filter="['activo', '=', 1]" :fields="[
            'nacionalidad',
            { name: 'divisa_id', fields: ['codigo', 'nombre', 'ratio'] },
            { name: 'impuesto_id', fields: ['nombre', 'valor'] },
            'descuento_especial',
            'financiacion',
            {
              name: 'bancos',
              fields: ['predet', 'titular', 'cuenta', 'swift'],
            },
            {
              name: 'direcciones',
              fields: [
                'predet',
                'descripcion',
                'responsable',
                'direccion',
                'direccion1',
                'cp',
                'poblacion',
                { name: 'provincia', fields: ['codigo', 'nombre'] },
                { name: 'pais', fields: ['codigo', 'nombre'] },
                'telefono',
                'telefono1',
                'movil',
                'movil1',
                'fax',
              ],
            },
            'margen',
            {
              name: 'representantes_ids',
              fields: [
                'codigo',
                'imagen',
                'nombre',
                { name: 'almacen', fields: ['codigo', 'nombre'] },
              ],
            },
            'tarifa',
            { name: 'comentarios', fields: ['descripcion', 'fecha', 'activo'] },
            {
              name: 'forma_pago_id',
              fields: [
                'codigo',
                'descripcion',
                'vencimientos',
                'periodicidad',
                'periodicidad_unidades',
              ],
            },
            'descuento_linea_unidad',
            'descuento_linea_peso',
            'recargo_equivalencia',
            'merma',
            'merma_linea'
          ]" />
        <a href="#" v-tooltip="'Mostrar comentarios'" class="button button-plain"
          :class="{ disabled: !formData.cliente_id }" style="position: absolute; top: 15px; right: 37px"
          @click="mostrarComentariosCliente">
          <fa-icon icon="sticky-note" :class="formData.cliente_id &&
            formData.cliente_id.comentarios &&
            formData.cliente_id.comentarios.length
            ? 'color-green'
            : ''
            " />
        </a>
        <a href="#" v-tooltip="'Abrir saldos'" class="button button-plain" :class="{ disabled: !formData.cliente_id }"
          style="position: absolute; top: 15px; right: 20px" @click="abrirSaldos">
          <fa-icon icon="dollar-sign" />
        </a>
        <span v-tooltip="parseInt(formData.cliente_id.recargo_equivalencia || 0)
          ? 'Tiene recargo de equivalencia'
          : 'No tiene recargo de equivalencia'
          " style="position: absolute; top: 20px; right: 8px; color: green" :style="parseInt(formData.cliente_id.recargo_equivalencia || 0)
            ? 'color: green;'
            : 'color: gray; text-decoration: line-through;'
            " v-if="formData.cliente_id">RE</span>
        <r-tabs ref="main-tabs" style="top: 40px; height: 373px" :wt="formData.wt" :buttons="[
          'Cabecera',
          'Envío',
          'Líneas',
          'Pie',
          'Vtos',
          'Taquería',
          'Estadísticas',
        ]" :classButton="{ disabled: !formData.cliente_id && mode != 'search' }" @active="onActiveTab">
          <div class="tab">
            <field name="npedido" widget="char" label="S. Nº de Pedido" width="80px" style="top: 10px; left: 10px" />
            <field name="fpedido" type="date" label="F. Pedido" width="100px" style="top: 10px; left: 100px" />
            <field name="fecha_servir_mercancia" type="date" label="F. Servir Mercancía" width="100px"
              style="top: 10px; left: 210px" />
            <field name="representante_id" widget="m2o" label="Representante" width="290px"
              style="top: 10px; right: 10px;" imgProp="imagen" :filter="['activo', '=', 1]"
              :fields="[{ name: 'almacen', fields: ['codigo', 'nombre'] }]" searchable />

            <fieldset style="
                position: relative;
                left: 10px;
                top: 50px;
                width: 230px;
                height: 59px;
              ">
              <legend>Exportación</legend>
              <field name="importacion" widget="radio" :readonly="true" default="union_europea" :options="{
                Nacional: 'nacional',
                'Extracom.': 'extracomunitario',
                UE: 'union_europea',
              }" inline-label style="top: 6px; left: 20px" />
            </fieldset>
            <field ref="divisa_id" name="divisa_id" widget="m2o" label="Divisa" width="120px"
              style="top: 115px; left: 10px" :fields="['ratio']" external-window="moneda" />
            <field name="cambio" widget="float" type="number" dec="6" label="Cambio" width="80px"
              style="top: 115px; left: 160px" :class="{ wrong: formData.divisa_id && !parseFloat(formData.cambio) }" />
            <fieldset style="
                position: relative;
                left: 250px;
                top: -9px;
                width: 380px;
                height: 59px;
              ">
              <legend>Metal</legend>
              <field ref="tipo_metal" name="tipo_metal" widget="radio" default="no"
                :options="{ No: 'no', Cuenta: 'cuenta', Factura: 'factura' }" style="top: 6px; left: 15px" />
              <field ref="metal_id" name="metal_id" widget="m2o" labelProp="descripcion" :readonly="formData.tipo_metal != 'cuenta' &&
                formData.tipo_metal != 'factura'
                " width="170px" style="top: 7px; left: 192px" :fields="['cotizacion', 'factor']" />
            </fieldset>

            <field name="prmetalp" widget="float" type="number" dec="2" inputStyle="text-align:center;"
              label="Pr. Metal/P" width="70px" style="top: 115px; left: 275px" :readonly="formData.tipo_metal != 'cuenta' &&
                formData.tipo_metal != 'factura'
                " />
            <field name="factor" widget="float" inputStyle="text-align:right;font-size:10px;" type="number" dec="4"
              inline-label label="Factor" width="40px" style="top: 153px; left: 275px" :readonly="true" />
            <field name="merma" widget="float" type="number" dec="2" inputStyle="text-align:center;" label="Merma (%/m)"
              width="70px" style="top: 115px; left: 350px" :readonly="formData.tipo_metal != 'cuenta' &&
                formData.tipo_metal != 'factura'
                " />
            <field name="contraste" widget="float" type="number" dec="2" inputStyle="text-align:center;"
              label="Contraste/P" width="70px" style="top: 115px; left: 425px" />
            <field name="ocostes" widget="float" type="number" dec="2" inputStyle="text-align:center;"
              label="O. Costes/P" width="70px" style="top: 115px; left: 500px" />
            <field name="ocostesu" widget="float" type="number" dec="2" inputStyle="text-align:center;"
              label="O. Costes/U" width="70px" style="top: 115px; left: 575px" />
            <field name="mermaporlinea" widget="checkbox" inline-label label="Merma por Línea"
              style="top: 153px; left: 526px" />

            <div v-if="formData.enviar_email_cliente" style="position:absolute; top: 155px; left: 10px;">
              <fa-icon icon="envelope" style=" color: red;" size="lg" />
              {{ formData.email_cliente }}
            </div>

            <field name="adjuntos" :max="6" widget="files" label="Adjuntos" style="top: 180px; left: 11px" />


            <field name="delegacion_id" widget="m2o" label="Deleg." width="230px" style="top: 175px; left: 219px;"
              :readonly="mode == 'edit'" inputStyle="font-weight:bold;text-align:center;" />
            <field name="tipo_id" widget="m2o" label="Tipo" width="185px" style="top: 175px; right: 10px"
              :readonly="mode == 'edit'" searchable />

            <field name="estado" widget="radio" class="pedido-color" :options="{
              Pendiente: 'pendiente',
              'En proceso': 'proceso',
              Preparado: 'preparado',
              Albaranado: 'albaranado',
              Facturado: 'facturado',
              Cancelado: 'cancelado',
            }" style="top: 219px; left: 218px" />
            <field name="observaciones" widget="text" label="Observaciones" width="636px" height="71px"
              style="top: 237px; left: 10px" />
          </div>
          <div class="tab">
            <!--Envío -->
            <fieldset name="envio" style="
                position: relative;
                left: 10px;
                top: 10px;
                width: 630px;
                height: 177px;
              ">
              <legend>Dirección Comercial</legend>
              <field name="dirc_tipo" labelProp="descripcion" primary="id" widget="m2o" width="413px"
                style="top: 5px; left: 8px" :fields="[
                  'descripcion',
                  'responsable',
                  'direccion',
                  'direccion1',
                  'cp',
                  'poblacion',
                  { name: 'provincia', fields: ['codigo', 'nombre'] },
                  { name: 'pais', fields: ['codigo', 'nombre'] },
                  'telefono',
                  'telefono1',
                  'movil',
                  'movil1',
                  'fax',
                ]" :readonly="!formData.cliente_id" :filter="formData.cliente_id
                  ? ['cliente_id', '=', formData.cliente_id.codigo]
                  : []
                  " />
              <field name="dirc_tipo.responsable" widget="char" label="Responsable" inline-label width="340px"
                style="top: 30px; right: 206px" :readonly="true" />
              <field name="dirc_tipo.direccion" widget="char" label="Dirección" inline-label width="340px"
                style="top: 55px; right: 206px" :readonly="true" />
              <field name="dirc_tipo.direccion1" widget="char" label="Dirección 1" inline-label width="340px"
                style="top: 80px; right: 206px" :readonly="true" />
              <field name="dirc_tipo.cp" widget="char" label="CP" inline-label width="100px"
                style="top: 105px; right: 446px" :readonly="true" />
              <field name="dirc_tipo.poblacion" widget="char" label="Población" inline-label width="180px"
                style="top: 105px; right: 206px" :readonly="true" />
              <field name="dirc_tipo.provincia" widget="m2o" label="Provincia" inline-label width="150px"
                style="top: 130px; right: 396px" :readonly="true" />
              <field name="dirc_tipo.pais" widget="m2o" label="País" inline-label width="150px"
                style="top: 130px; right: 206px" :readonly="true" />

              <field name="dirc_tipo.telefono" widget="char" label="Teléfono" inline-label width="100px"
                style="top: 5px; right: 8px" :readonly="true" />
              <field name="dirc_tipo.telefono1" widget="char" label="Teléfono 1" inline-label width="100px"
                style="top: 30px; right: 8px" :readonly="true" />
              <field name="dirc_tipo.movil" widget="char" label="Móvil" inline-label width="100px"
                style="top: 55px; right: 8px" :readonly="true" />
              <field name="dirc_tipo.movil1" widget="char" label="Móvil 1" inline-label width="100px"
                style="top: 80px; right: 8px" :readonly="true" />
              <field name="dirc_tipo.fax" widget="char" label="Fax" inline-label width="100px"
                style="top: 105px; right: 8px" :readonly="true" />
            </fieldset>

            <field label="Firma representante" name="firma_representante" widget="image"
              style="width: 150px; height: 65px; top: 252px; left: 320px" />
            <field label="Firma cliente" name="firma_cliente" widget="image"
              style="width: 150px; height: 65px; top: 252px; left: 480px" />
          </div>
          <div class="tab">
            <field ref="lineas" name="lineas" class="lineas" :htSettings="{ rowHeights: 15, cells: cellsLineas }"
              widget="handsontable" :height="316" :minRows="19" style="top: 5px; left: 4px; width: 648px"
              customprop="lineas_customprop" :fields="['precio_modificado']" :columns="[
                {
                  name: 'ean13',
                  header: '|||||||||',
                  help: 'Código de barras del artículo',
                },
                {
                  name: 'articulo_id',
                  header: 'Artículo',
                  help: 'Código del artículo',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  noOpen: true,
                  fields: [
                    { name: 'thumbnail', fields: ['imagen'] },
                    { name: 'familia_id', fields: ['codigo', 'nombre'] },
                    'tarifa1',
                    'tarifa2',
                    'tarifa3',
                    'tarifa4',
                    'tarifa5',
                    'tarifa6',
                    'tarifa7',
                    'tarifa8',
                    'tarifa9',
                    'tarifa10',
                  ],
                },
                {
                  name: 'descripcion',
                  help: 'Descripción del artículo',
                  header: 'Descripción',
                },
                {
                  name: 'modelo_id',
                  header: 'Modelo',
                  help: 'Modelo del artículo',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  readOnly: true,
                },
                //{name:'familia_id',header:'Familia', type:'m2o', primary:'codigo', label:'codigo', readOnly:true},
                { name: 'nota', help: 'Notas del artículo', header: 'Nota' },
                { name: 'posicion', help: 'Orden en que se pidió el artículo', header: 'Pos.', readOnly: true, },
                {
                  name: 'tt',
                  header: 'T',
                  help: 'Tipo de almacén',
                  //type: 'select',
                  //options: ['A', 'R'],
                },
                {
                  name: 'almacen_id',
                  header: 'Almacén',
                  help: 'Almacén',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  readOnly: true,
                },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  help: 'Unidades',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                { name: 'etiqueta', header: 'Etiq.', type: 'numeric' },
                {
                  name: 'peso',
                  header: 'Peso',
                  help: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'taqueria_ids',
                  header: 'Taquería',
                  help: 'Cód. Taqueria',

                  type: 'm2m',
                  primary: 'codigo',
                  image: 'thumbnail.imagen',
                  fields: ['precio_coste'],
                  label: 'codigo',
                  filter: () => ['seccion_id', '=', 'PCK'],
                },
                {
                  name: 'taqueria_precio',
                  header: 'Taq.(€)',
                  help: 'Coste total de taqueria',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'v',
                  header: 'V',
                  help: '<U> Venta por unidad <P> Venta por peso',
                  type: 'select',
                  options: ['U', 'P'],
                },
                {
                  name: 'm',
                  header: 'M',
                  help: 'Venta con metal incluido',
                  type: 'checkbox',
                },
                {
                  name: 'r',
                  header: 'R',
                  help: 'Venta con rec. equivalencia',
                  type: 'checkbox',
                },
                {
                  name: 'precio_coste',
                  header: 'P. Coste',
                  help: 'Precio coste artículo',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  renderer: rendererRojoNegativo,
                },
                {
                  name: 'tt2',
                  header: 'T',
                  help: 'Tarifa',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0' },
                },
                {
                  name: 'precio',
                  header: 'Precio',
                  help: 'Precio Venta',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  renderer: rendererNaranjaPrecioModificado,
                },
                {
                  name: 'liquidacion',
                  header: 'L',
                  help: 'Liquidación',
                  readOnly: true,
                  type: 'checkbox'
                },
                {
                  name: 'descuentos',
                  header: 'Dtos.',
                  help: 'Dto.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                /*{
                  name: 'descuento_peso',
                  header: 'Dto./peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },*/
                {
                  name: 'totallinea',
                  header: 'Total Línea',
                  help: 'Total Línea',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'm2',
                  header: 'M',
                  help: 'Marcar si se acumula el peso del metal',
                  type: 'checkbox',
                },
                {
                  name: 'c2',
                  header: 'C',
                  help: 'Marcar si se aplica Contraste por peso',
                  type: 'checkbox',
                },
                {
                  name: 'p',
                  header: 'P',
                  help: 'Marcar si se aplica Otros Costes por Peso',
                  type: 'checkbox',
                },
                {
                  name: 'u',
                  header: 'U',
                  help: 'Marcar si se aplica Otros Costes por Unidad',
                  type: 'checkbox',
                },
                {
                  name: 'com',
                  header: 'Com.',
                  help: 'Tipo de Comisión del artículo',
                  type: 'm2o',
                  label: 'codigo',
                  primary: 'codigo',
                  emptyOnError: true,
                },
                {
                  name: 'c',
                  header: 'C.',
                  help: '<D> Comisión Directa <I> Comisión Indirecta',
                  type: 'select',
                  options: ['D', 'I'],
                },
                {
                  name: 'margen',
                  header: '%',
                  help: '% de Margen para el calculo del PVP del cliente',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                {
                  name: 'pventa',
                  header: 'P.V.P.',
                  help: 'PVP del cliente',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                /*{ name: 'e', header: 'E', type: 'checkbox' },*/
                {
                  name: 'unidades_pendientes',
                  header: 'Uds.Pend.',
                  help: 'Unidades pendientes de albaranar/facturar',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso_pendiente',
                  header: 'Peso.Pend.',
                  help: 'Peso pendiente de albaranar/facturar',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'lineas_albaran_generadas',
                  header: 'Albaranes',
                  help: 'Albaranes generados',
                  type: 'm2m', //fix for fields to work
                  primary: 'id',
                  fields: [{ name: 'albaran_id', fields: ['ndoc'] }],
                  readOnly: true,
                  renderer: rendererLineasAlbaranGeneradas,
                },
                {
                  name: 'lineas_factura_generadas',
                  header: 'Facturas',
                  help: 'Facturas generadas',
                  type: 'm2m', //fix for fields to work
                  primary: 'id',
                  fields: [{ name: 'factura_id', fields: ['ndoc'] }],
                  readOnly: true,
                  renderer: rendererLineasFacturaGeneradas,
                },
              ]" />
          </div>
          <div class="tab">
            <field name="total_unidades" widget="float" type="number" dec="2" label="Total Unidades" width="90px"
              style="top: 10px; left: 15px" :readonly="true" />
            <field name="total_peso" widget="float" type="number" dec="3" label="Total Peso" width="90px"
              style="top: 10px; left: 115px" :readonly="true" suffix=" g" />
            <field name="g18k" widget="float" type="number" dec="3" :label="(formData.metal_id && formData.metal_id.descripcion) ||
              'G. Aleación'
              " width="90px" style="top: 50px; left: 15px" suffix=" g" />
            <field name="gmerma" widget="float" type="number" dec="3" label="G. Merma" width="90px"
              style="top: 50px; left: 115px" suffix=" g" />

            <field name="total_articulos" widget="float" type="number" dec="2" label="Total Artículos" inline-label
              width="70px" style="top: 10px; right: 12px" :readonly="true" />
            <field name="dto_especial_porcentaje" widget="float" type="number" dec="2" label="Dto. Especial"
              inline-label width="90px" style="top: 30px; right: 90px" suffix=" %" />
            <field name="dto_especial" widget="float" type="number" dec="2" width="70px"
              style="top: 30px; right: 12px" />

            <field name="g24k_porcentaje" widget="float" type="number" dec="3" :label="(formData.metal_id && formData.metal_id.descripcion) ||
              'G. Aleación'
              " inline-label width="90px" style="top: 50px; right: 90px" suffix=" g" :readonly="true" />
            <field name="g24k" widget="float" type="number" dec="2" width="70px" style="top: 50px; right: 12px"
              :readonly="true" />
            <field name="gcontraste_porcentaje" type="number" dec="3" widget="float" label="G. Contraste" inline-label
              width="90px" style="top: 70px; right: 90px" suffix=" g" />
            <field name="gcontraste" widget="float" type="number" dec="2" width="70px" style="top: 70px; right: 12px"
              :readonly="true" />
            <field name="gotroscostes_porcentaje" widget="float" type="number" dec="3" label="G. Otros Costes"
              inline-label width="90px" style="top: 90px; right: 90px" suffix=" g" />
            <field name="gotroscostes" widget="float" type="number" dec="2" width="70px" style="top: 90px; right: 12px"
              :readonly="true" />
            <field name="uotroscostes_porcentaje" widget="float" type="number" dec="3" label="U. Otros Costes"
              inline-label width="90px" style="top: 110px; right: 90px" />
            <field name="uotroscostes" widget="float" type="number" dec="2" width="70px" style="top: 110px; right: 12px"
              :readonly="true" />
            <field name="portesyseguros" widget="float" label="Portes y Seguros" inline-label width="70px"
              style="top: 130px; right: 12px" type="number" dec="2" />
            <field name="financiacion_porcentaje" widget="float" type="number" dec="2" label="Financiación" inline-label
              width="90px" style="top: 150px; right: 90px" suffix=" %" />
            <field name="financiacion" widget="float" type="number" dec="2" inline-label width="70px"
              style="top: 150px; right: 12px" />

            <field name="documento_exportado" widget="checkbox" label="Documento exportado" reverse inline-label
              style="top: 120px; left: 5px" />
            <field name="documento_factura_emitido" widget="checkbox" label="Documento de factura emitido" reverse
              inline-label style="top: 140px; left: 5px" />

            <div style="position: absolute; bottom: 0px">
              <table class="tabla-pie">
                <tr>
                  <td></td>
                  <th>B. Imponible</th>
                  <th>
                    {{ formData.tipo_impuesto }}
                    ({{ formData.ivap }}%)
                  </th>
                  <th>
                    Recargo ({{ $utils.misc.formatNumber(formData.rep) }}%)
                  </th>
                  <th>
                    <b>Totales</b>
                  </th>
                </tr>
                <tr>
                  <td style="width: 100px">Base 1 <small>(IMP.)</small></td>
                  <td>
                    <field name="baseimponible_sre" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </td>
                  <td>
                    <field name="iva_sre" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </td>
                  <td>
                    <field name="recargo_equivalencia_sre" widget="float" type="number" width="100%"
                      style="position: relative" :readonly="true" />
                  </td>
                  <td>
                    <field name="total2" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </td>
                </tr>
                <tr>
                  <td style="width: 115px">Base 2 <small>(IMP.+RE)</small></td>
                  <td>
                    <field name="baseimponible_re" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </td>
                  <td>
                    <field name="iva_re" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </td>
                  <td>
                    <field name="recargo_equivalencia_re" widget="float" type="number" width="100%"
                      style="position: relative" :readonly="true" />
                  </td>
                  <td>
                    <field name="total1" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </td>
                </tr>
                <tr>
                  <td>
                    Base Metal
                    <field name="base_metal_check" widget="checkbox" style="display: inline-flex" />
                  </td>
                  <td>
                    <field name="baseimponible_metal" widget="float" type="number" width="100%"
                      style="position: relative" :readonly="true" />
                  </td>
                  <td>
                    <field name="iva_metal" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </td>
                  <td>
                    <field name="recargo_equivalencia_metal" widget="float" type="number" width="100%"
                      style="position: relative" :readonly="true" />
                  </td>
                  <td>
                    <field name="total_metal" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </td>
                </tr>
                <tr>
                  <th colspan="4" class="total-cell">Rec. Financiero</th>
                  <th>
                    <field name="financiacion" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </th>
                </tr>
                <tr>
                  <th colspan="4" class="total-cell">Total</th>
                  <th>
                    <field name="total" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" inputStyle="font-weight:bold; background: rgba(0, 140, 186, 0.3)!important;" />
                  </th>
                </tr>
              </table>
            </div>
          </div>
          <div class="tab">
            <div class="item-input inline-label field" style="top: 10px; right: 530px">
              <div class="item-inner">
                <label class="item-title item-label">Base</label>
                <div class="item-input-wrap">
                  <input name="sum_bi" :value="sum_bi" type="text" style="width: 80px; text-align: right" readonly />
                </div>
              </div>
            </div>
            <div class="item-input inline-label field" style="top: 30px; right: 530px">
              <div class="item-inner">
                <label class="item-title item-label">IVA</label>
                <div class="item-input-wrap">
                  <input name="sum_iva" :value="sum_iva" type="text" style="width: 80px; text-align: right" readonly />
                </div>
              </div>
            </div>
            <div class="item-input inline-label field" style="top: 50px; right: 530px">
              <div class="item-inner">
                <label class="item-title item-label">RE</label>
                <div class="item-input-wrap">
                  <input name="sum_re" :value="sum_re" type="text" style="width: 80px; text-align: right" readonly />
                </div>
              </div>
            </div>
            <field name="total" widget="float" type="number" dec="2" label="Total" inline-label width="80px"
              style="top: 70px; right: 530px" :readonly="true" inputStyle="font-size:22px;" />

            <field name="total_unidades" widget="float" type="number" dec="2" label="Total Unidades" width="80px"
              style="top: 20px; right: 400px" :readonly="true" />
            <field name="total_peso" widget="float" type="number" dec="3" label="Total Peso" width="80px"
              style="top: 50px; right: 400px" :readonly="true" suffix=" g" />

            <field name="formapago_id" label="Forma de pago" widget="m2o" width="325px" style="top: 100px; left: 14px"
              labelProp="descripcion" :fields="[
                'vencimientos',
                'periodicidad',
                'periodicidad_unidades',
              ]" external-window="forma_pago" />

            <field name="titular" widget="char" label="Titular" inline-label placeholder="Titular" width="260px"
              style="top: 190px; left: 11px" />
            <fieldset style="
                position: absolute;
                left: 10px;
                top: 210px;
                width: 350px;
                height: 70px;
              ">
              <legend>Nº Cta.</legend>
              <div style="position: relative">
                <field name="cuenta" widget="char" label="Cuenta" width="234px" style="left: 0px" />

                <field name="swift" widget="char" label="Código Swift" width="92px" style="left: 240px" />
              </div>
            </fieldset>

            <field ref="vencimientos" name="vencimientos" widget="handsontable" label="Vencimientos" :height="223"
              style="top: 10px; right: 4px; width: 280px" :columns="[
                { name: 'fecha', header: 'Fecha', type: 'date2' },
                {
                  name: 'importe',
                  header: 'Importe',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
              ]" />
          </div>
          <div class="tab">
            <field ref="taqueria_lineas" name="taqueria_lineas" widget="handsontable" :height="278" :minRows="12"
              style="top: 10px; left: 10px; width: 637px" customprop="taquerialineas_customprop" :columns="[
                {
                  name: 'taqueria_id',
                  header: 'Artículo',
                  type: 'm2o',
                  primary: 'codigo',
                  showCode: true,
                  image: 'thumbnail.imagen',
                  label: 'nombre',
                  filter: (value, row, prop) => ['seccion_id', '=', 'PCK']
                },
                {
                  name: 'unidades_teoricas',
                  header: 'Uds. Pedidas',
                  type: 'numeric',
                  format: '0',
                  readOnly: true,
                },
              ]" />
          </div>
          <div class="tab">
            <field name="total_unidades" widget="float" type="number" dec="2" label="Total Unidades" inline-label
              width="80px" style="top: 10px; left: 14px" :readonly="true" />
            <field name="total_peso" widget="float" type="number" dec="3" label="Total Peso" inline-label width="80px"
              style="top: 10px; left: 195px" :readonly="true" suffix=" g" />
            <field name="total" widget="float" type="number" dec="2" label="Total Importe" inline-label width="80px"
              style="top: 10px; left: 350px" :readonly="true" />
            <div class="item-input inline-label field" style="top: 10px; left: 520px">
              <div class="item-inner">
                <label class="item-title item-label">€/pieza</label>
                <div class="item-input-wrap">
                  <input name="precio_medio_pieza" type="text" style="width: 80px; text-align: right"
                    :value="precioMedioPieza" readonly />
                </div>
              </div>
            </div>
            <field ref="estadisticas_familias" name="estadisticas_familias" widget="handsontable" :height="247"
              :minRows="10" style="top: 45px; left: 10px; width: 390px" :readonly="true" :columns="[
                {
                  name: 'familia_id',
                  header: 'Familia',
                  type: 'm2o',
                  label: 'nombre',
                  primary: 'codigo',
                  showCode: true,
                  model: 'familia',
                },
                {
                  name: 'unidades',
                  header: 'Unidades',
                  type: 'numeric',
                  format: '0',
                },
                {
                  name: 'precio_medio',
                  header: 'Precio medio',
                  type: 'numeric',
                  format: '0,0.00',
                },
              ]" />
            <field ref="historial_estados" name="historial_estados" widget="handsontable" :height="247" :minRows="10"
              style="top: 45px; left: 410px; width: 230px" :readonly="true" :columns="[
                {
                  name: 'estado',
                  header: 'Estado',
                  type: 'select',
                  options: {
                    pendiente: 'Pendiente',
                    proceso: 'En proceso',
                    preparado: 'Preparado',
                    albaranado: 'Albaranado',
                    facturado: 'Facturado',
                    cancelado: 'Cancelado',
                  },
                },
                {
                  name: 'create_date',
                  header: 'Fecha',
                  renderer: rendererDatetime,
                },
              ]" />
          </div>
        </r-tabs>
        <!-- tabs -->
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        Listado
        <div class="item-after">
          <field widget="select" name="anio" style="position: relative; width: 100px; margin-right: 24px;" width="100"
            @click="$event.stopPropagation()" :options="years.reduce((a, v) => ({ ...a, [v]: v }), {})" searchable
            :readonly="mode != 'search'" placeholder="Todos los años" v-if="years.length" />
          {{ count }}
        </div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import VentaMixin from "./../components/VentaMixin.js";
import FacturaMixin from "./../components/FacturaMixin.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin, FacturaMixin, VentaMixin],
  data: function () {
    var self = this;
    return {
      title: "Pedidos de cliente",
      dbAdapter: "pedido_cliente",
      primary: "id",
      defaultDataSearch: {
        wt: 0,
        anio: new Date().getFullYear(),
        //tipo_id: self.app.config.tipo_documento_defecto
      },
      defaultData: {
        wt: 0,
        fecha: new Date().yyyymmdd(),
        fpedido: new Date().yyyymmdd(),
        tipo_metal: "no",
        merma: 0,
        prmetalp: 0,
        contraste: 0,
        ocostes: 0,
        ocostesu: 0,
        delegacion_id: self.app.config.delegacion_defecto || null,
        portesyseguros: 0,
        estado: "pendiente",
        tipo_id: self.app.config.tipo_documento_defecto,
      },
      precioMenorPCosteConfirmado: false,
      validation: (data, resolve, reject) => {
        let self = this;
        if (!self.precioMenorPCosteConfirmado) {
          if (data.lineas.some(l => (l.precio || 0) < (l.precio_coste || 0))) {
            reject('Precio por debajo del precio de coste');
            self.app.confirm('Alguna de las líneas tiene el precio por debajo del precio de coste. ¿Desea guardar igualmente?', _ => {
              self.precioMenorPCosteConfirmado = true;
              self.saveItem();
            })
            return;
          }
        }
        resolve(data);
      }
      /*sequence: {
        field: "ndoc",
        name: function () {
          return self.formData.tipo_id
            ? "pedido_venta_" +
                (self.formData.wt ? "1" : "0") +
                "_" +
                self.formData.tipo_id.codigo
            : null;
        },
        params: ["tipo_id.codigo", "wt"],
      },*/
    };
  },
  mounted() {
    var self = this;
    setTimeout(function () {
      self.actualizarTodo(true);
    }, 1000);
  },
  methods: {
    cellsLineas: function (row, col, prop) {
      const self = this;
      let cellProperties = {};
      let h = self.$refs?.lineas?.field?.hotInstance;
      if (h && h.getSourceData()[row] && h.getSourceData()[row].id && !h.getSourceData()[row].unidades_pendientes && !h.getSourceData()[row].peso_pendiente) {
        cellProperties.readOnly = true;
      }
      return cellProperties;
    },
    onActiveTab(i) {
      let self = this;
      switch (i) {
        case 3: //pie
        case 4: //vtos
        case 5: //taqueria
        case 6: //estadisticas
          self.actualizar_total_unidades();
          self.actualizar_total_peso();
          self.actualizar_total_articulos();
          self.actualizar_g18k();
          self.actualizar_gmerma();
          self.actualizar_gcontraste_porcentaje();
          self.actualizar_gotroscostes_porcentaje();
          self.actualizar_uotroscostes_porcentaje();
          self.actualizar_taqueria();
          self.actualizar_estadisticas_familias();
          break;
      }
    },
    imprimirPedido() {
      let self = this;
      window.DB.put("pedido_cliente", {
        itemId: self.itemId,
        data: { estado: "proceso" },
      }).then(() => self.loadItem(self.itemId));
    },
    facturar() {
      let self = this;
      let initialFormData = window.$.extend(true, {}, self.formData);

      initialFormData.lineas = initialFormData.lineas.map(l => ({
        ean13: l.ean13,
        articulo_id: l.articulo_id,
        descripcion: l.descripcion,
        modelo_id: l.modelo_id,
        nota: l.nota,
        tt: l.tt,
        almacen_id: l.almacen_id,
        unidades: l.unidades_pendientes || 0,
        etiqueta: l.etiqueta,
        peso: l.peso_pendiente || 0,
        taqueria_ids: l.taqueria_ids,
        taqueria_precio: l.taqueria_precio,
        v: l.v,
        m: l.m,
        r: l.r,
        precio_coste: l.precio_coste,
        tt2: l.tt2,
        precio: l.precio,
        descuentos: l.descuentos,
        totallinea: l.totallinea,
        m2: l.m2,
        c2: l.c2,
        p: l.p,
        u: l.u,
        com: l.com,
        c: l.c,
        margen: l.margen,
        pventa: l.pventa,
        origin_pedido_id: { id: l.id, pedido_id: { ndoc: initialFormData.ndoc } },
      }));
      initialFormData.pedido_id = {
        id: self.itemId,
        ndoc: self.formData.ndoc,
        tipo_id: {
          codigo: self.formData.tipo_id.codigo,
          nombre: self.formData.tipo_id.nombre,
        },
        cliente_id: {
          codigo: self.formData.cliente_id.codigo,
          nombre_comercial: self.formData.cliente_id.nombre_comercial
        },
        total: self.formData.total
      };
      //initialFormData.formapago_id = self.formData.cliente_id.forma_pago_id;
      delete initialFormData.id;
      delete initialFormData.ndoc;
      delete initialFormData.fecha;
      initialFormData.lineas = initialFormData.lineas.filter(l => (l.v == 'U' && l.unidades) || (l.v == 'P' && l.peso));
      self.app.openWindow("factura_cliente", {
        props: {
          initialMode: "new",
          initialFormData: initialFormData,
        },
      });
    },
    albaranar() {
      let self = this;
      let initialFormData = window.$.extend(true, {}, self.formData);
      initialFormData.lineas = initialFormData.lineas.map(l => ({
        ean13: l.ean13,
        articulo_id: l.articulo_id,
        descripcion: l.descripcion,
        modelo_id: l.modelo_id,
        nota: l.nota,
        tt: l.tt,
        almacen_id: l.almacen_id,
        unidades: l.unidades_pendientes || 0,
        etiqueta: l.etiqueta,
        peso: l.peso_pendiente || 0,
        taqueria_ids: l.taqueria_ids,
        taqueria_precio: l.taqueria_precio,
        v: l.v,
        m: l.m,
        r: l.r,
        precio_coste: l.precio_coste,
        tt2: l.tt2,
        precio: l.precio,
        descuentos: l.descuentos,
        totallinea: l.totallinea,
        m2: l.m2,
        c2: l.c2,
        p: l.p,
        u: l.u,
        com: l.com,
        c: l.c,
        margen: l.margen,
        pventa: l.pventa,
        origin_pedido_id: { id: l.id, pedido_id: { ndoc: initialFormData.ndoc } },
      }));
      initialFormData.pedido_id = {
        id: self.itemId,
        ndoc: self.formData.ndoc,
        tipo_id: {
          codigo: self.formData.tipo_id.codigo,
          nombre: self.formData.tipo_id.nombre,
        },
        cliente_id: {
          codigo: self.formData.cliente_id.codigo,
          nombre_comercial: self.formData.cliente_id.nombre_comercial
        },
        total: self.formData.total
      };
      //initialFormData.formapago_id = self.formData.cliente_id.forma_pago_id;
      delete initialFormData.id;
      delete initialFormData.ndoc;
      delete initialFormData.fecha;
      delete initialFormData.npedido;
      delete initialFormData.fpedido;
      initialFormData.lineas = initialFormData.lineas.filter(l => (l.v == 'U' && l.unidades) || (l.v == 'P' && l.peso));
      self.app.openWindow("albaran_cliente", {
        props: {
          initialMode: "new",
          initialFormData: initialFormData,
        },
      });
    },
    openURL(url, params = {}) {
      params.token = window.session.token;
      let ser_params = window.$.param(params);
      window.open(url + '&' + ser_params, "_blank");
    }
  },
};
</script>
<style>
.pedido-color.field[name="estado"] .buttonset .button.active {
  background-color: #ffffbb;
  color: black;
}

.pedido-color.field[name="estado"] input[value="proceso"]+.button.active {
  background-color: #aadaff;
  color: black;
}

.pedido-color.field[name="estado"] input[value="preparado"]+.button.active {
  background-color: #cbaaff;
  color: black;
}

.pedido-color.field[name="estado"] input[value="albaranado"]+.button.active {
  background-color: #ffca8e;
  color: black;
}

.pedido-color.field[name="estado"] input[value="facturado"]+.button.active {
  background-color: #cec;
  color: black;
}

.pedido-color.field[name="estado"] input[value="cancelado"]+.button.active {
  background-color: #ffbbbb;
  color: black;
}

.pedido-color .button {
  padding: 3px 7.3px;
}
</style>